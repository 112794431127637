<template>
	<section class="section border-b" id="about"
		:style="{ 'background-image': 'url(' + require('@/assets/images/bg/pattern4.png') + ')', 'background-size': 'cover' }">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-5 order-1 order-lg-1">
					<div class="features-img mx-auto mr-lg-0 padding-lr-10">
						<img src="@/assets/images/bg/company1.png" alt="macbook image" class="img-fluid" />
					</div>
				</div>
				<div class="col-lg-7 order-2 order-lg-2">
					<div class="features-boxmt-lg-0 mainp">
						<p class="font-24 black8">山东纪元信息科技</p>
						<p class="black7 font-11">SHANDONG JIYUAN INFORMATION TECHNOLOGY CO.,LTD.</p>
						<p class="black7 margin-t-30">
							山东纪元信息科技有限公司位于山东省潍坊市高新区，是一家专业从事软件开发、软件定制、软件实施的高新技术企业。拥有一批长期专业从事软件开发、软件定制的专业人才，具有雄厚的技术开发实力，致力于提供行业解决方案和满足企业信息化需求。
						</p>
						<p class="black7">
							公司重视企业内部管理工作，围绕市场销售、软件研发、技术支持三大核心部门建立了完善的管理体制。提供以客户为中心的个性化服务，以技术为中心的行业解决方案，以数据为中心的综合供应链能力。
						</p>
						<p class="black7">
							公司的核心研发团队诞生于2006年英国第一网络计算中心，2007年研究方向为分布式计算、无线通信系统以及窄带通信和区块链理论研究；2009年至今的技术产品转化阶段，陆续开发完成航空公司全球在线订票系统、NTFS国际信用证开证系统；2014年研发方向转为面向互联网的开发，于2016年完成了SCM供应链管理系统和元系列医疗互联网化解决方案的设计思路、技术应用、产品规划和原型设计；2017年开发了启元近视防控监测平台、洪元数据采集系统、归元数据可视化看板、坤元信息管理系统；2018年完成开元互联网医院并投放市场；2019年开发MICA智能通信配件。
						</p>
						<p class="black7">目前公司在北京、重庆、济宁等地设立办公室，致力于为客户提供24小时技术支持。</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: 'About'
};
</script>
<style scoped></style>
